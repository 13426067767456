<template>
    <div class="w-full h-full bg-[#F7F9FB] overflow-scroll pb-10">
        <div v-for="(question, index) in exam" :key="index">

            <div
                class="quickQuestionItem relative rounded-lg w-100 flex items-center cursor-pointer hover:bg-[#EFF5FC] hover:shadow-lg mt-5 mx-4 py-5 flex justify-center">
                <div class="w-1/2 flex">
                    <div class="w-full">
                        <div class="flex">
                            <div class="flex-1">
                                <div class="flex">
                                    <div class="hover:bg-slate-300 rounded p-2 flex justify-center mr-3">

                                    </div>
                                    <span class="font-semibold text-lg">Soru {{ index + 1 }}</span>
                                </div>
                                <div class="w-full mt-3" style="min-height: 50px;" v-html="question.title"></div>
                            </div>
                            <div v-if="question.image"
                                class="flex items-center overflow-hidden justify-center text-slate-500 transition duration-150 w-72 h-48 ease-out imageHolder rounded bg-white border border-slate-400 hover:shadow border-dashed">
                                <img :src="question.image" alt="">
                            </div>
                        </div>
                        <div>

                            <div class="mt-5 flex flex-col gap-3">
                                <div @click="checkAnswer(index, option.optionId)"
                                    v-for="(option, optionIndex) in question.options" :key="optionIndex"
                                    :class="[option.optionId == question.checkedAnswer ? 'bg-sky-400 text-white' : 'bg-white']"
                                    class="w-full rounded p-2 hover:bg-sky-400 flex items-center"
                                    style="min-height: 2.5rem;">
                                    {{ option.value }}
                                    <div v-if="option.optionId == question.checkedAnswer"
                                        class="ml-auto w-6 h-6 rounded-full bg-white flex items-center justify-center">
                                        <span class="text-sky-400">
                                            ✓
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex w-full justify-center items-center mt-5">
            <button @click="finishIncoreQuiz"
                class="bg-[#4D00B3] px-10 py-2 rounded cursor-pointer hover:bg-[#6e00ff] text-white">
                Bitir
            </button>
        </div>

    </div>
</template>
<script>
import { computed, ref, onBeforeMount, onMounted } from 'vue';
export default {

    props: {
        modelValue: {
            default: () => ({})
        },
    },
    setup(props, { emit }) {

        const getExam = computed({
            get: () => props.modelValue,
            set: (value) => emit('update:modelValue', value)
        })

        const timer = ref();

        const totalDuration = ref(0);
        const avarageAnswerDurationCounter = ref(0);
        const totalDurationToAnswer = ref(0)




        const exam = ref(getExam.value.questions)
        const checkAnswer = (questionIndex, optionId) => {
            if (!exam.value[questionIndex].checkedAnswer) {
                totalDurationToAnswer.value += avarageAnswerDurationCounter.value;
                avarageAnswerDurationCounter.value = 0;
            }

            exam.value[questionIndex].checkedAnswer = optionId;
        }

        const calcValues = computed(() => {
            let data = { totalDuration: totalDuration.value, avgDuration: 0, totalAnsweredQuestion: 0, totalCorrect: 0, totalWrong: 0, totalPoint: 0, totalNull: 0 }
            exam.value.forEach((question) => {
                console.log("SORu:", question);
                if (question.checkedAnswer) {
                    data.totalAnsweredQuestion++;
                    if (question.checkedAnswer == question.correctAnswer) {
                        data.totalCorrect++;
                        console.log(data.totalPoint);
                        console.log(question.point)
                        data.totalPoint += question.point;
                    }
                    else {
                        data.totalWrong++;
                    }
                }
                else {
                    data.totalNull++;
                }
            })
            console.log("TOPLAM SÜRE:", totalDurationToAnswer.value);
            if (data.totalAnsweredQuestion)
                data.avgDuration = totalDurationToAnswer.value / data.totalAnsweredQuestion;
            return data;
        });
        const finishIncoreQuiz = () => {


            clearInterval(timer.value);


            emit('onQuizFinished', calcValues.value)
        }

        onBeforeMount(() => {
            let point = 0;
            exam.value.forEach((question) => {
                point += question.point;
            });
            emit('onQuizInit', { totalQuestion: exam.value.length, totalPoint: point })
        });

        onMounted(() => {
            timer.value = setInterval(() => {
                totalDuration.value++;
                avarageAnswerDurationCounter.value++;
            }, 1000)

        })
        return {
            exam,
            checkAnswer,
            finishIncoreQuiz
        }
    }
}
</script>
<style>
.h0i10 {
    scroll-behavior: smooth;
}

::placeholder {
    color: #000 !important;
}

.quickQuestionItemButtons {
    display: none;
}

.quickQuestionItem:hover .quickQuestionItemButtons {
    display: block;
}


.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.ghost {
    background: #c8ebfb;
    padding: 10px;
    border: 1px solid dashed;
    border-radius: 5px;
}

.ghostQuestion {
    background: rgba(51, 51, 51, 0.463);
    border: 5px solid dashed;
    border-radius: 10px;
    position: relative;
    opacity: 0.9;
}

.ghostQuestion::after {
    content: "Yer Değiştirmek İçin Bırakın";
    position: absolute;
    background: rgba(0, 0, 0, 0.512);
    display: flex;
    align-items: center;
    justify-content: center;
    inset: 0;
    width: 100%;
    height: 100%;
    color: #fff;
    border-radius: 10px;
}

.el-upload-dragger {
    padding: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.el-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>